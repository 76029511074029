<template lang="pug">
div.asset-controls.flex
  i.nexd-icon-16-edit(v-tooltip="{value: tooltip}" @click="editHandler")
  i.nexd-icon-16-counter-clockwise(v-if="can_reset" @click="resetHandler" v-tooltip="{value: 'Reset to default'}")
</template>

<script>
import AssetHelper from '@helpers/Asset';
import Upload from '@helpers/Upload';

import CustomAssetService from '@master/Services/Cache/CustomAssetService';
import CropperMixins from '@master/Mixins/CropperMixins.vue';
import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import AssetTraits from '@cm/Views/Creatives/Traits/AssetTraits.vue';
import { SIZE, ASSET } from '@master/constants';

const TYPES = ['control', 'audio', 'fullscreen', 'replay'];

export default {
  name: 'CustomVideoControlAsset',
  mixins: [CreativeTraits, AssetTraits, CropperMixins],
  props: {
    asset_id: String,
    creative: Object,
    arno: Object,
    crop_settings: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
      required: true,
      validator: type => TYPES.includes(type),
    },
  },

  computed: {
    fullscreen() {
      return this.type === 'fullscreen';
    },
    audio() {
      return this.type === 'audio';
    },
    replay() {
      return this.type === 'replay';
    },

    tooltip() {
      if (this.fullscreen) return 'Edit fullscreen button';
      if (this.audio) return 'Edit audio controls';
      if (this.replay) return 'Edit replay button';
      return 'Edit play controls';
    },

    // array of assets for MVP where single edit is used for both assets
    // first element in the array will be used for croping visuals
    assets() {
      if (this.fullscreen) return ['custom_video_fullscreen'];
      if (this.audio) return ['custom_video_mute', 'custom_video_unmute'];
      if (this.replay) return ['custom_video_replay'];
      return ['custom_video_play', 'custom_video_pause'];
    },

    main_asset_id() {
      return `${this.assets[0]}_${this.asset_id}`;
    },

    can_reset() {
      return this.creative?.additional_assets?.[this.main_asset_id] != null;
    },

    can_use_arno() {
      return this.arno?.api != null;
    },

    asset() {
      // mock asset obj for the mixin
      return {
        position: 'absolute',
        type: ASSET.OVERLAY,
      };
    },
  },

  data() {
    return {
      loading: false,
      loading_percentage: 0,
      files: {},
    };
  },

  methods: {
    async editHandler() {
      await this.getFiles();
      this.initCroppingTool();

      this.Cropper.onsave = settings => {
        // only show notification once
        let notification = true;
        let error_msg = [];
        let file_count = 0;

        // save settings are applied for both assets
        for (const asset_specific_asset_id in this.files) {
          const file = this.files[asset_specific_asset_id];

          // if we have a file, upload it
          if (file instanceof File) {
            const { width, height } = this.Cropper.getFileDimensions();

            this.loading = 'Uploading';
            this.loading_percentage = 0;
            const handler = new Upload(file);
            handler.upload(({ percentage, done, upload_id, error }) => {
              this.loading_percentage = percentage;
              if (done) {
                const obj = {
                  upload_id: upload_id,
                  crop: true,
                  hidden: true,
                  settings,
                };
                this.upload(asset_specific_asset_id, obj, {
                  type: file.type,
                  width,
                  height,
                });
              }

              if (error?.message != null) {
                error_msg.push(error.message);
              }

              if (done || error) file_count += 1;

              if (file_count === Object.keys(this.files).length && error_msg.length > 0) {
                this.loading = null;
                this.loading_percentage = null;
                this.$alert(error_msg.join(', '));
              }
            });
          } else {
            // if already a custom file, edit it
            const path = `creatives/${this.creative.creative_id}/assets/${asset_specific_asset_id}`;
            this.$http.put(path, { settings }, { notification }).then(response => {
              this.$set(this.creative.additional_assets, asset_specific_asset_id, response);

              // update local reference so reopen cropping would use correct settings
              this.$set(this.files, asset_specific_asset_id, this.creative.additional_assets[asset_specific_asset_id]);
              this.$emit('change');
            });

            // set false, so next loop cycle would not show notification
            notification = false;
          }
        }
      };

      // first file listed in will be used for cropping
      const main_asset = this.files[this.main_asset_id];

      // if its file loaded by backend, trigger input change event
      if (main_asset instanceof File) {
        return this.Cropper.inputChangeHandler({
          target: {
            files: [main_asset],
          },
        });
      }

      // open saved file state
      this.Cropper.editAsset(main_asset.original_url, main_asset.settings);
    },

    async resetHandler() {
      this.loading = 'Resetting';
      for (const asset_id of this.assets) {
        const asset_specific_asset_id = `${asset_id}_${this.asset_id}`;
        this.$delete(this.creative.additional_assets, asset_specific_asset_id);
        const path = `creatives/${this.creative.creative_id}/assets/${asset_specific_asset_id}`;
        this.$http.delete(path, {}, { notification: false }).catch(_ => {
          /** suppress backend errors */
        });
      }
      this.$emit('change');
      this.loading = null;
    },

    upload(asset_specific_asset_id, obj) {
      this.loading = 'Optimizing';
      const path = `creatives/${this.creative.creative_id}/assets/${asset_specific_asset_id}`;
      this.$http
        .post(path, obj, { notification: false })
        .then(response => {
          this.$set(this.creative.additional_assets, asset_specific_asset_id, response);

          // update local reference if it was downloaded as file before
          this.$set(this.files, asset_specific_asset_id, this.creative.additional_assets[asset_specific_asset_id]);
          this.$emit('change');
        })
        .finally(_ => {
          this.loading = null;
          this.loading_percentage = null;
        });
    },

    async getFiles() {
      for (const asset_id of this.assets) {
        const asset_specific_asset_id = `${asset_id}_${this.asset_id}`;

        // set null to check if anything is loading
        this.$set(this.files, asset_specific_asset_id, false);

        if (this.creative?.additional_assets?.[asset_specific_asset_id] != null) {
          this.$set(this.files, asset_specific_asset_id, this.creative.additional_assets[asset_specific_asset_id]);
          continue;
        }

        // all default video controls are the same, so we can use the same cache key
        const collection = CustomAssetService.get(asset_id);
        let active_original_file = collection.first();

        // files were cached
        if (active_original_file == null) {
          await collection.load();
        }

        active_original_file = collection.first();
        this.$set(this.files, asset_specific_asset_id, active_original_file.file);
      }
    },

    initCroppingTool() {
      // for parent asset type checks n stuff
      const sizes = AssetHelper.getSize(this.creative, this.asset_id);

      // panorama exception, use placement not the asset area
      let cropSize = sizes.dimensions;
      if (sizes.panorama) {
        cropSize = this.crop_settings.placement.size;
      }

      let is_parent_overlay = false;
      const is_parent_hotspot = AssetHelper.isCustomHotspotMain(this.asset_id);

      let parent_asset = null;
      const template_asset = this.creative.template?.assets[this.asset_id];

      if (template_asset) {
        is_parent_overlay = this.isOverlay(template_asset.type);
        parent_asset = this.creative.assets?.[this.asset_id];
      } else if (AssetHelper.isAdditional(this.asset_id)) {
        is_parent_overlay = true;
        parent_asset = this.creative.additional_assets?.[this.asset_id];
      }

      if (is_parent_hotspot) {
        parent_asset = this.creative?.additional_assets?.[this.asset_id];
      }

      let offset = { ...this.crop_settings.placement.offset };

      if ((is_parent_overlay || is_parent_hotspot) && parent_asset?.settings?.SourceLayer) {
        const { position, size: sourcelayer_size, offset: source_offset } = parent_asset.settings.SourceLayer;
        const { size: croplayer_size } = parent_asset.settings.CropLayer;

        cropSize.width = Math.min(croplayer_size.width, sourcelayer_size.width);
        cropSize.height = Math.min(croplayer_size.height, sourcelayer_size.height);

        // only consider offset change if sourcelayer is bigger than croplayer (cropped both edges)
        if (croplayer_size.width > sourcelayer_size.width) {
          offset.x = position.x;

          // if sourcelayer is over the edge
          if (source_offset.rx < 0) {
            const half_offset = source_offset.rx * 0.5;

            // take or add half of the half offset, since anchor is in center (depends on asset offset)
            if (source_offset.x < 0) {
              offset.x -= half_offset * 0.5;
            } else {
              offset.x += half_offset * 0.5;
            }
            // take down half offset, because scale is 2
            cropSize.width += half_offset;
          }
        }

        // only consider offset change if sourcelayer is bigger than croplayer (cropped both edges)
        if (croplayer_size.height > sourcelayer_size.height) {
          offset.y = position.y;

          // if sourcelayer is over the edge
          if (source_offset.ry < 0) {
            const half_offset = source_offset.ry * 0.5;

            // take or add half of the half offset, since anchor is in center (depends on asset offset)
            if (source_offset.y < 0) {
              offset.y -= half_offset * 0.5;
            } else {
              offset.y += half_offset * 0.5;
            }
            // take down half offset, because scale is 2
            cropSize.height += half_offset;
          }
        }
      }

      const cropper_options = {
        cropSize,
        achor: 'center',
        position: 'absolute',
        grid: 16,
        scale: 2, // force scale 2
        clip: {
          horizontal: true,
          vertical: true,
        },
        placement: {
          size: this.crop_settings.placement.size,
          adsize: this.crop_settings.placement.adsize,
          offset,
        },
        wrapper: {
          size: this.crop_settings.wrapper.size,
        },
        reset_transform: {},
      };

      if (this.isMobile()) {
        cropper_options.scale *= SIZE.FW.INTERSTITIAL.WIDTH / this.creative.width;
      }

      // MVP hardcode reset positions for assets
      if (this.audio) {
        // mute/unmute & admin fullscreen right
        cropper_options.reset_transform = {
          r: 0.95,
          b: 0.95,
          unit: '%',
        };
      } else if (this.fullscreen) {
        // fullscreen right, before mute/unmute
        // close enough, might need pixel calulation for defaults, but since its an admin feature :shrug:
        cropper_options.reset_transform = {
          r: 0.8,
          b: 0.95,
          unit: '%',
        };
      } else if (this.replay) {
        // replay is in center
        cropper_options.reset_transform = {
          unit: '%',
        };
      } else {
        // play/pause left side
        cropper_options.reset_transform = {
          l: -0.95,
          b: 0.95,
          unit: '%',
        };
      }

      this.Cropper.init(cropper_options);

      this.Cropper.beforeShow = (done, cropper) => {
        this.cropperBackgroundHandler(done, cropper, this.main_asset_id, this);
      };
    },
  },
};
</script>
